import { Component, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

declare let fbq: Function;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnDestroy {
    versionSubscription: Subscription;
    title = 'http-client';

    constructor(private swUpdate: SwUpdate, private router: Router) {
        this.versionSubscription = this.swUpdate.available.subscribe((evt) => {
            console.warn('NUEVA VERSION');
            window.location.reload();
        });

        router.events.subscribe((nav: NavigationEnd) => {
            if (nav instanceof NavigationEnd) {
                fbq('track', 'PageView');
            }
        });
    }

    ngOnDestroy(): void {
        this.versionSubscription.unsubscribe();
    }
}
