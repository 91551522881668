import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BLOGS } from './pages/data';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
})
export class BlogComponent {
    popularBlogs = BLOGS;
    blogIndex = 0;

    constructor(private router: Router) {}

    getBlog(selectedBlog: string) {
        this.router.navigate([this.router.url, selectedBlog]);
    }

    getPreviousBlog(): void {
        if (this.blogIndex === 0) {
            this.blogIndex = 2;
        } else {
            this.blogIndex--;
        }
    }

    getNextBlog(): void {
        if (this.blogIndex === 2) {
            this.blogIndex = 0;
        } else {
            this.blogIndex++;
        }
    }
}
