import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MailerService } from '../../services/mailer.service';
import { SharedPopupComponent } from '../shared-popup/shared-popup.component';
import { SUBSCRIPTION_SUCCESS, WRONG_EMAIL } from 'src/app/homepage/home/home.dialogs';

declare let fbq: Function;

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {
    email: string;

    constructor(private mailerService: MailerService, public dialog: MatDialog) { }

    subscribe(): void {
        if (this.email && this.email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')) {
            this.mailerService.createNewsletterSubscription(this.email).then(
                () => this.dialog.open(SharedPopupComponent, SUBSCRIPTION_SUCCESS(this)),
                (error) => console.log(error)
            );
        } else {
            this.dialog.open(SharedPopupComponent, WRONG_EMAIL(this));
        }
        fbq('track', 'CompleteRegistration');
    }
}
