import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MailerService } from '../../../../shared/services/mailer.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedPopupComponent } from '../../../../shared/components/shared-popup/shared-popup.component';
import { CARD_REGISTRED } from '../register.dialogs';

declare let fbq: Function;

@Component({
    selector: 'app-card-registration',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent {
    cardForm = new FormGroup({
        name: new FormControl(''),
        email: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]),
        children: new FormControl(''),
        city: new FormControl(''),
    });
    constructor(private mailerService: MailerService, private dialog: MatDialog) {}

    submitCard(): void {
         this.mailerService.cardRegistration(this.cardForm.value).then(
            (data) => this.dialog.open(SharedPopupComponent, CARD_REGISTRED(this)),
            (error) => console.log(error)
        );
        fbq('track', 'SubmitApplication');
    }
}
