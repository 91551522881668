import { AbstractControl } from '@angular/forms';

export const cardNumber = new RegExp(
    [
        '^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])' +
            '[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$',
    ].join('')
);

export function EmailValidator(control: AbstractControl) {
    if (control.value.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')) {
        return null;
    } else {
        return { email: 'El correo no es válido' };
    }
}

export function FullNameValidator(control: AbstractControl) {
    if (control.value.length > 0) {
        return null;
    } else {
        return { fullName: 'El nombre no es válido' };
    }
}

export function PhoneValidator(control: AbstractControl) {
    if (control.value.length >= 8) {
        return null;
    } else {
        return { phone: 'El teléfono no es válido' };
    }
}

export function CountryValidator(control: AbstractControl) {
    if (control.value.length > 0) {
        return null;
    } else {
        return { country: 'El páis no es válido' };
    }
}

export function StateValidator(control: AbstractControl) {
    if (control.value.length > 0) {
        return null;
    } else {
        return { state: 'El estado no es válido' };
    }
}

export function CityValidator(control: AbstractControl) {
    if (control.value.length > 0) {
        return null;
    } else {
        return { city: 'La ciudad no es válida' };
    }
}

export function ZipCodeValidator(control: AbstractControl) {
    if (control.value.length === 5 && /^\d+$/.test(control.value)) {
        return null;
    } else {
        return { zipCode: 'El codigo Postal no es válido' };
    }
}

export function ExternalNumberValidator(control: AbstractControl) {
    if (control.value.length > 0) {
        return null;
    } else {
        return { externalNumber: 'El número exterior no es válido' };
    }
}

export function AddressValidator(control: AbstractControl) {
    if (control.value.length > 0) {
        return null;
    } else {
        return { address: 'La colonia no es válida' };
    }
}

export function StreetsValidator(control: AbstractControl) {
    if (control.value.length > 0) {
        return null;
    } else {
        return { streets: 'La calle no es válida' };
    }
}

export function CardNumberValidator(control: AbstractControl) {
    if (control.value.length > 0 && cardNumber.test(control.value)) {
        return null;
    } else {
        return { cardNumber: 'La tarjeta no es válida' };
    }
}

export function CVCValidator(control: AbstractControl) {
    if ((control.value.length === 3 || control.value.length === 4) && /^\d+$/.test(control.value)) {
        return null;
    } else {
        return { cvcNumber: 'El código de seguridad no es válido' };
    }
}

export function ExpirationMonthValidator(control: AbstractControl) {
    if (control.value.length === 2 && /^\d+$/.test(control.value)) {
        return null;
    } else {
        return { expirationMonth: 'La fecha de expiración no es válida' };
    }
}

export function ExpirationYearValidator(control: AbstractControl) {
    if (control.value.length === 4 && /^\d+$/.test(control.value)) {
        return null;
    } else {
        return { expirationYear: 'El año de expiración no es válido' };
    }
}

export function QuantityValidator(control: AbstractControl) {
    if (control.value > 0 && control.value < 10 && /^\d+$/.test(control.value)) {
        return null;
    } else {
        return { quantity: 'La cantidad no es válida' };
    }
}
