import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPinService } from './reset-pin.service';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';

@Component({
    selector: 'app-reset-pin',
    templateUrl: './reset-pin.component.html',
    styleUrls: ['./reset-pin.component.scss']
})
export class ResetPinComponent implements OnInit {
    dialogRef;

    constructor(
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private resetPinService: ResetPinService,
        private router: Router
    ) {}

    ngOnInit() {
        const resetToken = this.activatedRoute.snapshot.paramMap.get('token');
        const options = {
            width: '400px',
            disableClose: true,
            data: {
                title: 'Cambio de Pin',
                content: {
                    type: 'input-option',
                    options: [
                        {
                            text: 'PIN',
                            value: '',
                            disabled: false,
                            type: 'password'
                        },
                        {
                            text: 'Repetir PIN',
                            value: '',
                            disabled: false,
                            type: 'password'
                        }
                    ]
                },
                error: undefined,
                buttons: [
                    {
                        text: 'Guardar',
                        action: () => {
                            const pin = options.data.content.options[0].value;
                            const repin = options.data.content.options[1].value;
                            if (pin !== repin) {
                                options.data.error = 'Las PINs no coinciden';
                            } else {
                                this.resetPinService.resetPin(pin, resetToken).then(
                                    data => {
                                        this.passwordUpdatedSuccess();
                                    },
                                    error => {
                                        this.passwordUpdatedError();
                                    }
                                );
                            }
                        }
                    }
                ]
            }
        };

        this.dialog.open(SharedPopupComponent, options);
    }

    passwordUpdatedSuccess(): void {
        this.dialog.closeAll();
        this.dialog.open(SharedPopupComponent, {
            width: '500px',
            data: {
                title: 'Tu PIN se ha actualziado exitosamente',
                buttons: [
                    {
                        text: 'Ok',
                        action: () => {
                            this.router.navigate(['']);
                            this.dialog.closeAll();
                        }
                    }
                ]
            }
        });
    }

    passwordUpdatedError(): void {
        this.dialogRef = this.dialog.open(SharedPopupComponent, {
            width: '500px',
            data: {
                title: 'Lo sentimos no pudimos actualizar tu PIN.',
                subtitle: 'Si el problema persiste, por favor contacte al equipo de soporte.',
                buttons: [
                    {
                        text: 'Ok',
                        action: () => {
                            this.dialogRef.close();
                        }
                    }
                ]
            }
        });
    }
}
