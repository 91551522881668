import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomepageNavbarComponent } from './homepage-navbar.component';
import { SharedPopupModule } from '../shared-popup/shared-popup.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [HomepageNavbarComponent],
    imports: [CommonModule, SharedPopupModule, RouterModule],
    exports: [HomepageNavbarComponent],
})
export class HomepageNavbarModule {}
