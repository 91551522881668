import { Component } from '@angular/core';
import { MailerService } from 'src/app/shared/services/mailer.service';
import { SharedPopupComponent } from 'src/app/shared/components/shared-popup/shared-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SUBSCRIPTION_SUCCESS, WRONG_EMAIL } from '../home/home.dialogs';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent {
    email;

    constructor(private mailerService: MailerService, private dialog: MatDialog) {}

    subscribe(): void {
        if (this.email && this.email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')) {
            this.mailerService.createNewsletterSubscription(this.email).then(
                () => this.dialog.open(SharedPopupComponent, SUBSCRIPTION_SUCCESS(this)),
                (error) => console.log(error)
            );
        } else {
            this.dialog.open(SharedPopupComponent, WRONG_EMAIL(this));
        }
    }
}
