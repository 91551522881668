import { Component, OnInit, OnDestroy } from "@angular/core";
import { OptionsNavbarService } from "../../shared/components/global-header/option-navbar.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  OS: string;
  navbarOptionSubscription: Subscription;
  /**
  first_slider = {
    direction: "horizontal",
    loop: true,
    slidesPerView: 4,
    pagination: true,
    navigation: false,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
  };
  second_slider = {
    direction: "horizontal",
    slidesPerView: 4,
    pagination: true,
    navigation: false,
    centeredSlides: false,
    loop: false,
    autoplay: undefined,
  };

  test_scripts = [];
  test_html_id = [];
  */

  constructor(
    private optionsNavbarService: OptionsNavbarService,
    private router: Router,
    private http: HttpClient
  ) {
    /**if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      this.OS = "iOS";
      if (window.screen.width < 768) {
        this.first_slider.slidesPerView = 1;
        this.second_slider.slidesPerView = 1;
        this.second_slider.loop = true;
        this.second_slider.autoplay = {
          delay: 1500,
          disableOnInteraction: false,
        };
      }
    } else if (navigator.userAgent.match(/Android/i)) {
      this.OS = "Android";
      if (window.screen.width < 768) {
        this.first_slider.slidesPerView = 1;
        this.second_slider.slidesPerView = 1;
        this.second_slider.loop = true;
        this.second_slider.autoplay = {
          delay: 1500,
          disableOnInteraction: false,
        };
      }
    } else {
      this.OS = "Other";
    }*/
  }

  ngOnInit(): void {
    // const paramsData = {

    // };
    // const params = new HttpParams({ fromObject: paramsData });

    // this.http
    //   .get(`http://localhost:9000/content`)
    //   .toPromise()
    //   .then((data) => {
    //     console.log(data);
    //     this.test_html_id = data["id"];
    //     this.test_scripts = data["scripts"];

    //     this.test_scripts.forEach((script_id) => {
    //       const node = document.createElement("script"); // creates the script tag
    //       node.src = `http://localhost:9000${script_id}`; // sets the source (insert url in between quotes)
    //       node.type = "text/javascript"; // set the script type
    //       node.async = true;
    //       // append to head of document
    //       document.getElementsByTagName("head")[0].appendChild(node);
    //       console.log(script_id);
    //     });
    //   });

    /**this.navbarOptionSubscription = this.optionsNavbarService.optionSelected.subscribe(
      (option) => {
        if (option === "programas") {
          setTimeout(() => {
            document
              .getElementById("programas")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }, 500);
        }
      }
    );*/
  }

  openPage(url): void {
    window.open(url, "_blank");
  }

  ngOnDestroy(): void {
    if (this.navbarOptionSubscription) {
      this.navbarOptionSubscription.unsubscribe();
    }
  }
}
