import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MailerService } from '../../../../shared/services/mailer.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedPopupComponent } from '../../../../shared/components/shared-popup/shared-popup.component';
import { SCHOOL_REGISTRED } from '../register.dialogs';

declare let fbq: Function;

@Component({
    selector: 'app-school-registration',
    templateUrl: './school.component.html',
    styleUrls: ['./school.component.scss'],
})
export class SchoolComponent {
    schoolForm = new FormGroup({
        school: new FormControl(''),
        name: new FormControl(''),
        email: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]),
        phone: new FormControl(''),
        grades: new FormControl(''),
    });

    constructor(private mailerService: MailerService, private dialog: MatDialog) {}

    submit(): void {
        this.mailerService.schoolRegistration(this.schoolForm.value).then(
            (data) => this.dialog.open(SharedPopupComponent, SCHOOL_REGISTRED(this)),
            (error) => console.log(error)
        );
        fbq('track', 'SubmitApplication');
    }
}
