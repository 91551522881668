import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-introduction',
    templateUrl: './introduction.component.html',
    styleUrls: ['./introduction.component.scss'],
})
export class IntroductionComponent implements OnInit {
    slide = 0;

    constructor() {}

    ngOnInit() {}

    nextSlide(): void {
        this.slide++;
    }
}
