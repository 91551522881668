export function SUBSCRIPTION_SUCCESS(context) {
    const options = {
        width: '350px',
        data: {
            title: '¡Gracias!',
            content: {
                type: 'text',
                text: ['Te has suscrito exitosamente.'],
            },
            buttons: [
                {
                    text: 'Ok',
                    action: () => context.dialog.closeAll(),
                },
            ],
        },
    };
    return options;
}

export function WRONG_EMAIL(context) {
    const options = {
        width: '400px',
        data: {
            title: 'Aviso',
            content: {
                type: 'text',
                text: ['Favor de usar un correo válido.'],
            },
            buttons: [
                {
                    text: 'Ok',
                    action: () => context.dialog.closeAll(),
                },
            ],
        },
    };
    return options;
}
