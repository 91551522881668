import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RegisterService {
    private requestInProgress = false;

    constructor(private http: HttpClient) {}

    registerParent(parent): Promise<any> {
        parent.email = parent.email.toLowerCase();

        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/parents`, { parent })
                .pipe(
                    tap(
                        () => (this.requestInProgress = false),
                        () => (this.requestInProgress = false)
                    )
                )
                .toPromise();
        }
    }

    registerStudent(student): Promise<any> {
        student.email = student.email.toLowerCase();

        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/students`, { student })
                .pipe(
                    tap(
                        () => (this.requestInProgress = false),
                        () => (this.requestInProgress = false)
                    )
                )
                .toPromise();
        }
    }
}
