import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';

import { HomePageComponent } from './homepage/homepage.component';
import { HOMEPAGE_ROUTES } from './homepage/homepage.routing';
import { MODULES_ROUTES } from './modules/modules-routing.module';
import { ModulesComponent } from './modules/modules.component';

import { TOOLS_ROUTES } from './tools/tools.routing';
import { ToolsComponent } from './tools/tools.component';

export const routes: Routes = [
    { path: '', component: HomePageComponent, children: HOMEPAGE_ROUTES },
    { path: '', component: ModulesComponent, children: MODULES_ROUTES },
    { path: '', component: ToolsComponent, children: TOOLS_ROUTES },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            // preloadingStrategy: PreloadAllModules
            onSameUrlNavigation: 'reload',
            preloadingStrategy: NoPreloading,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
