import { JSDocTagName } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { UserProfileService } from "../../shared/services/userProfile.service";
import CONTENT from "./teachers.content.js";

@Component({
    selector: 'app-teachers',
    templateUrl: './teachers.component.html',
    styleUrls: ['./teachers.component.scss'],
})
export class TeachersComponent implements OnInit {
    first_slider = {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 4,
        pagination: true,
        navigation: false,
        autoplay: {
            delay: 1500,
            disableOnInteraction: false,
        },
    };
    userType;
    userLevel;
    userLang;
    content = CONTENT;

    constructor(
        private userProfileService: UserProfileService

    ) {
        if (window.screen.width < 768) {
            this.first_slider.slidesPerView = 1;
        }
    }

    ngOnInit() {
        this.userType = this.userProfileService.getUserType();
        this.userLevel = this.userProfileService.getUserLevel();
        this.userLang = this.userProfileService.getLang();
        this.content = CONTENT;
    }
}
