import { PinLoginComponent } from './pin-login/pin-login.component';
import { Routes } from '@angular/router';

export const TOOLS_ROUTES: Routes = [
    {
        path: 'pin-login/:teacher_enrollment_id',
        component: PinLoginComponent,
    },
    {
        path: 'pin-login/:teacher_enrollment_id/:group_name',
        component: PinLoginComponent,
    }
];
