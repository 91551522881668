import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare let fbq: Function;
@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
    cart;
    total;

    constructor(private router: Router) { }

    ngOnInit() {
        if (JSON.parse(localStorage.getItem('Cart'))) {
            this.cart = JSON.parse(localStorage.getItem('Cart'));
        } else {
            this.cart = [];
        }

        this.getCartTotal();
    }

    formatCart() {
        let tempCart = this.cart;
        for (let cartItemIndex = 0; cartItemIndex < tempCart.length; cartItemIndex++) {
            const cartItem = tempCart[cartItemIndex];

            if (cartItem.product.extraInformation && cartItem.product.extraInformation.length > 0) {
                let pastDetails = cartItem.product["details"] && cartItem.product["details"].length > 0 ? cartItem.product["details"] : []
                cartItem.product["details"] = []

                for (let detailAmount = 0; detailAmount < cartItem.quantity; detailAmount++) {
                    let currentDetails = pastDetails[detailAmount]

                    let fullInfo = []
                    for (let detailItemIndex = 0; detailItemIndex < cartItem.product.extraInformation.length; detailItemIndex++) {
                        const infoObj = cartItem.product.extraInformation[detailItemIndex];
                        let tempInfoObj = infoObj
                        
                        if(tempInfoObj.isVisible)
                            tempInfoObj.value = currentDetails ? currentDetails[detailItemIndex].value : "";
                        
                        fullInfo.push({ ...tempInfoObj });
                    }
                    cartItem.product.extraInformation.forEach(infoObj => {
                    });
                    cartItem.product["details"].push(fullInfo);
                }
            }
        }
        this.cart = tempCart
    }

    canPay(): boolean {
        let canPay = this.cart.length > 0;

        this.cart.forEach(cartItem => {
            if (canPay) {
                canPay = (cartItem.quantity <= cartItem.product.maxAmount || cartItem.product.maxAmount === 0) && cartItem.quantity > 0;

                if (canPay && cartItem.product.details) {
                    cartItem.product.details.forEach(itemDetails => {
                        itemDetails.forEach(detail => {
                            if(detail.isVisible){
                                if (canPay) canPay = detail.value && detail.value.length > 0
                            }
                        });
                    });
                }
            }
        });

        return canPay;
    }

    loadFieldName(fieldName, index){
      return fieldName.replace("$INDEX", index)
    }

    getCartTotal(): void {
        this.formatCart();
        this.total = 0;
        this.cart.forEach((cartItem) => {
            this.total += cartItem.product.price * cartItem.quantity;
        });

        localStorage.setItem('Cart', JSON.stringify(this.cart));
    }

    deleteCartItem(cartItemSelected): void {
        this.cart = this.cart.filter((cartItem) => {
            let a = (cartItemSelected.product.cartId ? cartItemSelected.product.cartId : cartItemSelected.product.id)
            let b = (cartItem.product.cartId ? cartItem.product.cartId : cartItem.product.id)
            
            return a != b
        });
        this.getCartTotal();
        localStorage.setItem('Cart', JSON.stringify(this.cart));
    }

    priceChange(): void {
        setTimeout(() => {
            this.getCartTotal();
        });
    }

    goToCheckout(): void {
        this.cart = this.cart.filter((cartItem) => cartItem.quantity !== null || cartItem.quantity > 0);
        console.log(this.cart);
        localStorage.setItem('Cart', JSON.stringify(this.cart));
        this.router.navigate(['/carrito/pago']);
        fbq('track', 'AddPaymentInfo');
    }
}
