import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { UserProfileService } from '../../services/userProfile.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-childs-navbar',
    templateUrl: './childs-navbar.component.html',
    styleUrls: ['./childs-navbar.component.scss'],
})
export class ChildsNavbarComponent implements OnInit, OnDestroy {
    @Input() childs;
    selectedChild;
    childSubscription: Subscription;

    constructor(private userProfileService: UserProfileService) {}

    ngOnInit() {
        this.childSubscription = this.userProfileService.childSelected.subscribe((child) => {
            if (child) {
                this.selectedChild = child;
            }
        });
    }

    setChild(child): void {
        this.userProfileService.setChild(child);
        this.selectedChild = child;
    }

    ngOnDestroy(): void {
        this.childSubscription.unsubscribe();
    }
}
