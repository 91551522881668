import { Component } from '@angular/core';

@Component({
    selector: 'app-inverlingo',
    templateUrl: './inverlingo.component.html',
    styleUrls: ['./inverlingo.component.scss'],
})
export class InverlingoComponent {
    OS;

    constructor() {
        // if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        //     this.OS = 'iOS';
        // } else if (navigator.userAgent.match(/Android/i)) {
        //     this.OS = 'Android';
        // } else {
        //     this.OS = 'Other';
        // }
    }
}
