import { Component, OnInit } from '@angular/core';
import { StoreService } from '../product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PRODUCTS } from '../../../../assets/store_files/products.data';
import { SharedPopupComponent } from '../../../shared/components/shared-popup/shared-popup.component';
import { MatDialog } from '@angular/material/dialog';

declare let fbq: Function;
@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
    productId;
    products = PRODUCTS.sort((n1, n2) => n1.id - n2.id);
    quantity = 1;

    constructor(
        private storeService: StoreService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.loadProduct();
    }

    loadProduct(): void {
        this.productId = +this.activatedRoute.snapshot.paramMap.get('product_id');
        const cartItems = JSON.parse(localStorage.getItem('Cart'));
        if (cartItems && cartItems.length > 0) {
            this.storeService.cart = cartItems;
        }
        fbq('track', 'ViewContent');
    }

    addToCart(): void {
        const productIndex = this.storeService.cart.findIndex((cartItem) => cartItem.product.id === this.productId);
        if (productIndex >= 0) {
            this.storeService.cart = this.storeService.cart.filter((cartItem) => cartItem.product.id !== +this.productId);
        }
        this.storeService.cart.push({
            product: this.products[this.productId],
            quantity: this.quantity,
        });
        localStorage.setItem('Cart', JSON.stringify(this.storeService.cart));

        this.dialog.open(SharedPopupComponent, {
            width: '400px',
            data: {
                title: 'Se ha agregado el producto a tu carrito',
                buttons: [
                    {
                        text: 'Ok',
                        action: () => {
                            this.dialog.closeAll();
                        },
                    },
                ],
            },
        });
        fbq('track', 'AddToCart', {
            value: this.products[this.productId].price,
            currency: 'MXN',
            content_type: 'product',
            content_ids: this.productId,
        });
    }

    payProduct(): void {
        this.addToCart();
        this.router.navigate(['carrito']);
    }

    goToCart(): void {
        this.router.navigate(['carrito']);
    }

    selectProduct(productIndex): void {
        this.router.navigate([`tienda/producto/${productIndex}`]).then(() => this.loadProduct());
    }
}
