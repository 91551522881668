const CONTENT = {
  1: {
    en: "Profile",
    es: "Perfil",
  },
  2: {
    en: "Change user",
    es: "Cambiar usuario",
  },
  3: {
    en: "Help",
    es: "Ayuda",
  },
  4: {
    en: "Log out",
    es: "Cerrar sesión",
  }
};

export default CONTENT;