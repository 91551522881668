import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ActivatedRoute } from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class UserProfileService {
    requestInProgress;
    userProfile;
    private childSubject = new BehaviorSubject(null);
    childSelected = this.childSubject.asObservable();
    private userType = new BehaviorSubject(null);
    currentType = this.userType.asObservable();
    lastChildSelected;
    module;
    topic;

    constructor(private http: HttpClient) {}

    getUserProfile(): Promise<any> {
        return this.http
            .get<any>(`${environment.apiUrl}/profile`)
            .pipe(
                tap((user) => {
                    this.userProfile = user;
                    this.setAccountType(user["account_type"]);
                    this.setUserType(user["user_type"]);
                    this.setUserRole(user["role"]);
                    this.setUserLevel(user["level"]);
                    this.setLang(user["lang"]);
                    this.setWhiteLabel(user["white_label"]);
                    
                    window.localStorage.setItem("progress", JSON.stringify(user["progress"]))
                })
            )
            .toPromise();
    }

    setLangRemote(lang): Promise<any> {
        this.setLang(lang);
        return this.http
        .post<any>(`${environment.apiUrl}/profile/set_lang`, { lang: lang }).toPromise();
    }

    setProgress(progress): Promise<any> {
        window.localStorage.setItem('progress', JSON.stringify(progress));
        return this.http
        .post<any>(`${environment.apiUrl}/profile/set_progress`, { progress: progress }).toPromise();
    }

    getProgress(): any {
        let progress = window.localStorage.getItem('progress')
        if (progress == 'undefined'){
            return null
        }else{
            return JSON.parse(window.localStorage.getItem('progress'));
        }
    }

    setLang(lang): void {
        console.log("setLang:", lang)
        window.localStorage.setItem('lang', lang);
    }

    getLang(): String {
        return window.localStorage.getItem('lang');
    }

    setWhiteLabel(white_label): void {
        console.log("setLabel:", white_label)
        window.localStorage.setItem('white_label', white_label);
    }

    getWhiteLabel(): String {
        return window.localStorage.getItem('white_label');
    }

    setNickname(nickname): Promise<any> {
        return this.http
        .post<any>(`${environment.apiUrl}/profile/set_nickname`, { nickname: nickname }).toPromise();
    }

    validateAccount(email): Promise<any> {
        return this.http
            .post<any>(`${environment.apiUrl}/profile/validate_account`, { user: { email } })
            .toPromise();
    }

    checkEmailVerification(email): Promise<any> {
        return this.http
            .post<any>(`${environment.apiUrl}/profile/verify_account`, { user: { email } })
            .toPromise();
    }

    sendEmailVerification(email): Promise<any> {
        return this.http
            .post<any>(`${environment.apiUrl}/profile/send_email_verification`, { user: { email } })
            .toPromise();
    }

    setUserProfile(profile): void {
        this.userProfile = profile;
    }

    getUserType(): string {
        return window.localStorage.getItem('UserType');
    }

    getUserLevel(): string {
        return window.localStorage.getItem('UserLevel');
    }

    getUserRole(): string {
        return window.localStorage.getItem('UserRole');
    }

    setUserType(userType: string): void {
        window.localStorage.setItem('UserType', userType);
    }

    setUserLevel(userLevel: string): void {
        window.localStorage.setItem('UserLevel', userLevel);
    }

    setUserRole(userRole: string): void {
        window.localStorage.setItem('UserRole', userRole);
    }

    removeUserType(): void {
        window.localStorage.removeItem('UserType');
    }

    getAuthToken(): string {
        return window.localStorage.getItem('AuthToken');
    }

    getUserId(): string {
        return window.localStorage.getItem('UserId');
    }

    setUserId(user_id: string): void {
        window.localStorage.setItem('UserId', user_id);
    }

    setAuthToken(authToken: string): void {
        window.localStorage.setItem('AuthToken', authToken);
    }

    removeAuthToken(): void {
        window.localStorage.removeItem('AuthToken');
    }

    getAccountType(): string {
        return window.localStorage.getItem('AccountType');
    }

    setAccountType(accountType: string): void {
        window.localStorage.setItem('AccountType', accountType);
    }

    removeAccountType(): void {
        window.localStorage.removeItem('AccountType');
    }

    getParentToken(): string {
        return window.localStorage.getItem('ParentToken');
    }

    setParentToken(parentToken: string): void {
        window.localStorage.setItem('ParentToken', parentToken);
    }

    removeParentToken(): void {
        window.localStorage.removeItem('ParentToken');
    }

    setChild(child: any): void {
        this.childSubject.next(child);
        this.lastChildSelected = child;
    }

    changeUserType(type: string) {
        this.userType.next(type);
    }

    updateProfile(user): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .put(`${environment.apiUrl}/profile`, { user })
                .pipe(
                    tap(
                        () => (this.requestInProgress = false),
                        () => (this.requestInProgress = false)
                    )
                )
                .toPromise();
        }
    }

    updateChildAccount(child): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .put(`${environment.apiUrl}/childs/${child.id}`, { child })
                .pipe(
                    tap(
                        () => (this.requestInProgress = false),
                        () => (this.requestInProgress = false)
                    )
                )
                .toPromise();
        }
    }

    setNavbarChild(): void {
        if (!this.lastChildSelected) {
            this.setChild(this.userProfile.child_accounts[0]);
        } else {
            this.setChild(this.userProfile.child_accounts.find((child) => child.id === this.lastChildSelected.id));
        }
    }
}
