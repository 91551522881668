import { Component, OnInit, Input, OnDestroy, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { UserProfileService } from "../../services/userProfile.service";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { OptionsNavbarService } from "./option-navbar.service";

import CONTENT from "./global-header.content.js";
@Component({
  selector: "app-global-header",
  templateUrl: "./global-header.component.html",
  styleUrls: ["./global-header.component.scss"],
})
export class GlobalHeaderComponent implements OnInit, OnDestroy, OnChanges {
  content = CONTENT;
  @Input() user;
  parentToken;

  // Left container
  @Input() goBackArrow;
  @Input() showDropdown;

  // Midle container
  @Input() navbarTitle;

  // Right container
  @Input() exitRoute;
  @Input() showCurrency;
  @Input() showHitpoints;
  @Input() showEarnings;

  // Styles
  @Input() textColor;
  @Input() whiteCross;
  @Input() whiteArrow;
  @Input() backgroundColor;
  @Input() dropDownColor;

  // Bottom navbar
  @Input() bottomNavbarOptions;
  optionSelected: string;
  optionSelectedSubscription: Subscription;
  userChangedSubscription: Subscription;

  // Refactor needed
  @Input() showChildsNavbar;
  @Input() childs;

  dropdownToggled;

  userLang;

  constructor(
    private location: Location,
    private router: Router,
    private userProfileService: UserProfileService,
    private optionsNavbarService: OptionsNavbarService
  ) {}

  ngOnInit(): void {
    this.userLang = this.userProfileService.getLang();
    this.userChangedSubscription = this.optionsNavbarService.userChanged.subscribe(
      () => {
        this.getUser();
      }
    );
    this.optionSelectedSubscription = this.optionsNavbarService.optionSelected.subscribe(
      (option) => {
        this.optionSelected = option;
      }
    );
  }

  ngOnChanges(changes) {
    console.log("Header Changes: ", changes, this.user);
  }

  getUser(): void {
    this.userProfileService
      .getUserProfile()
      .then((data) => {
        this.user = data;
        this.parentToken = this.userProfileService.getParentToken();
      })
      .catch((error) => console.log(error));
  }

  goBack(route?): void {
    if (route) {
      this.router.navigate(route);
    } else {
      this.location.back();
    }
  }

  goTo(route): void {
    if (route === "back") {
      window.history.go(-1);
    } else {
      this.router.navigate(route);
    }
  }

  logout(): void {
    this.userProfileService.removeAccountType();
    this.userProfileService.removeAuthToken();
    this.userProfileService.removeParentToken();
    this.userProfileService.removeUserType();
    this.router.navigate(["/"]);
  }

  selectOption(option: string) {
    this.optionsNavbarService.setOptionSelected(option);
  }

  ngOnDestroy(): void {
    if (this.optionSelectedSubscription) {
      this.optionSelectedSubscription.unsubscribe();
    }

    if (this.userChangedSubscription) {
      this.userChangedSubscription.unsubscribe();
    }
  }

  shortName(name) {
    console.log(name);
    const split_name = name.split(" ");
    return split_name[0];
  }
}
