export const SCHOOL_GAMES = [
    { module: 1, topic: 1, game_index: 1, game_name: 'Ahorro' },
    { module: 1, topic: 1, game_index: 2, game_name: 'Gasto' },
    { module: 1, topic: 1, game_index: 3, game_name: 'Inversión' },
    { module: 1, topic: 1, game_index: 4, game_name: 'Donación' },
    { module: 2, topic: 1, game_index: 1, game_name: 'Trueque' },
    { module: 2, topic: 1, game_index: 2, game_name: 'Metales' },
    { module: 2, topic: 1, game_index: 3, game_name: 'Dinero' },
    { module: 2, topic: 1, game_index: 4, game_name: 'Tarjetas' },
    { module: 2, topic: 2, game_index: 1, game_name: 'Imprenta' },
    { module: 2, topic: 3, game_index: 1, game_name: 'Divisas' },
    { module: 2, topic: 4, game_index: 1, game_name: 'Profesiones' },
    { module: 3, topic: 1, game_index: 1, game_name: 'AhorroNew' },
    { module: 3, topic: 2, game_index: 1, game_name: 'MetasFinancieras' },
    { module: 3, topic: 3, game_index: 1, game_name: 'Deseos' },
    { module: 3, topic: 4, game_index: 1, game_name: 'AhorroFormal' },
];
