import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as blogs from './data';
import { Blog } from 'src/app/shared/models/blog.model';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-blog-template',
    templateUrl: './blog_template.component.html',
    styleUrls: ['./blog_template.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BlogTemplateComponent implements OnInit {
    selectedBlog: string;
    blog: Blog;

    constructor(private activatedRoute: ActivatedRoute, private location: Location) {}

    ngOnInit() {
        this.selectedBlog = this.activatedRoute.snapshot.paramMap.get('blog_name');
        this.getSelectedBlogContent();
    }

    getSelectedBlogContent(): void {
        for (const blog of blogs.BLOGS) {
            if (this.selectedBlog === blog.id) {
                this.blog = blog;
            }
        }
    }

    goBack(): void {
        this.location.back();
    }
}
