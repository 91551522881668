import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResetPinService {
    requestInProgress = false;

    constructor(private http: HttpClient) {}

    resetPinMail(): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/reset_pin`, {})
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }

    resetPin(pin: string, token: string): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .put<any>(`${environment.apiUrl}/reset_pin`, { pin, token })
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }
}
