import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {
    requestInProgress = false;

    constructor(private http: HttpClient) {}

    resetPasswordMail(username: string): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/reset_password`, { username })
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }

    resetPassword(password: string, token: string): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .put<any>(`${environment.apiUrl}/reset_password`, { password, token })
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }
}
