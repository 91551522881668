import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterService } from '../../register.service';
import { UserProfileService } from '../../../../shared/services/userProfile.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedPopupComponent } from '../../../../shared/components/shared-popup/shared-popup.component';
import { EMAIL_TAKEN } from '../register.dialogs';

declare let fbq: Function;

@Component({
    selector: 'app-parent-registration',
    templateUrl: './parent.component.html',
    styleUrls: ['./parent.component.scss'],
})
export class ParentComponent {
    step = 0;
    parentForm = new FormGroup({
        name: new FormControl(''),
        nickname: new FormControl(''),
        birthday: new FormControl(''),
        avatar: new FormControl(''),
        pin: new FormControl(''),
        pin_confirmation: new FormControl(''),
        gender: new FormControl(''),
        email: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]),
        password: new FormControl(''),
        password_confirmation: new FormControl(''),
        user_type: new FormControl('Parent'),
    });
    colors = ['azul', 'naranja', 'morado', 'verde', 'rosa'];
    today = new Date().toISOString().substring(0, 10);

    constructor(
        private registerService: RegisterService,
        private userProfileService: UserProfileService,
        private authenticationService: AuthenticationService,
        private dialog: MatDialog
    ) {}

    setGender(gender): void {
        this.parentForm.patchValue({ gender });
    }

    selectAvatar(avatar): void {
        this.parentForm.patchValue({ avatar });
    }

    isEmailAndPasswordValid(): boolean {
        if (
            this.parentForm.get('email'.trim()).dirty &&
            this.parentForm.get('password'.trim()).dirty &&
            this.parentForm.get('password_confirmation'.trim()).dirty
        ) {
            return (
                this.parentForm.get('password'.trim()).value === this.parentForm.get('password_confirmation').value &&
                this.parentForm.get('email'.trim()).valid &&
                this.parentForm.get('password'.trim()).value.length >= 5
            );
        } else {
            return false;
        }
    }

    selectSavingsAccount(savings_account): void {
        this.parentForm.patchValue({ savings_account });
    }

    isPinValid(): boolean {
        return this.parentForm.get('pin').value === this.parentForm.get('pin_confirmation').value && this.parentForm.get('pin').valid;
    }

    submit(): void {
        this.registerService
            .registerParent(this.parentForm.value)
            .then((data) => this.authenticateUser())
            .catch((error) => {
                if (error.status === 400) {
                    this.dialog.open(SharedPopupComponent, EMAIL_TAKEN(this));
                }
            });
        fbq('track', 'CompleteRegistration');
    }

    authenticateUser(): void {
        this.authenticationService
            .authenticateUser({
                enrollment_id: this.parentForm.get('email').value.trim(),
                password: this.parentForm.get('password').value.trim(),
            })
            .then((data) => {
                this.step = 7;
                this.userProfileService.setAuthToken(data.auth_token);
                this.userProfileService.setUserType(data.user_type);
                this.userProfileService.setAccountType(data.account_type);
            });
    }
}
