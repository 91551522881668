import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, NavigationEnd } from "@angular/router";

import { Subscription } from "rxjs";

import { LoginComponent } from "src/app/homepage/login/login.component";
import { OptionsNavbarService } from "../global-header/option-navbar.service";
import { UserProfileService } from "../../services/userProfile.service";

import { SHOW_DOWNLOADS } from "../../../../assets/public_files/downloads.data";

@Component({
  selector: "app-navbar",
  templateUrl: "./homepage-navbar.component.html",
  styleUrls: ["./homepage-navbar.component.scss"],
})
export class HomepageNavbarComponent implements OnDestroy {
  showDownloads = SHOW_DOWNLOADS;
  toggled = false;
  routerSubscription: Subscription;
  blueBackground: boolean;
  OS: string;
  userLang = 'es';
  visible = true;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private optionsNavbarService: OptionsNavbarService,
    private userProfileService: UserProfileService
  ) {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.toggled = false;

        if ( 
          this.router.url.includes("QRcode") ||
          this.router.url.includes("VCard") 
          ) {
          this.visible = false;
        }

        if (
          this.router.url.includes("blog")
        ) {
          this.blueBackground = false;
        } else {
          this.blueBackground = true;
        }
      }
    });

    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      this.OS = "iOS";
    } else if (navigator.userAgent.match(/Android/i)) {
      this.OS = "Android";
    } else {
      this.OS = "Other";
    }


  }

  setLang(): void {
    this.userLang = this.userLang === 'es' ? 'en' : 'es';
    this.userProfileService.setLang(this.userLang);
  }

  goToRoute(route): void {
    this.openMenu();
    this.router.navigate([route]);
  }

  openDialog(): void {
    this.dialog.open(LoginComponent, { width: "350px" });
  }

  openMenu() {
    this.toggled = !this.toggled;
  }
  /**
  scrollToPrograms(): void {
    this.router.navigate(["/"]);
    this.optionsNavbarService.setOptionSelected("programas");
  }
  */

  resetOpion(): void {
    this.optionsNavbarService.setOptionSelected(null);
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
