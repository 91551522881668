import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
// import { AuthService, FacebookLoginProvider, SocialUser, GoogleLoginProvider } from 'angularx-social-login';

import { ResetPasswordService } from '../../reset-password/reset-password.service';
import { UserProfileService } from '../../../shared/services/userProfile.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { UserCredentials } from '../../../shared/models/user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-login-redirect',
    templateUrl: './loginRedirect.component.html',
    styleUrls: ['./loginRedirect.component.scss'],
})
export class LoginRedirectComponent {

    loginForm = new FormGroup({
        enrollmentIdForm: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]),
        password: new FormControl(''),
    });

    subtabSelected: string;
    enrollmentId: string;
    password: string;
    enrollmentIdError = false;
    passwordError = false;
    userCredentials = new UserCredentials();
    socialSubscription: Subscription;
    // user: SocialUser;
    username: string;
    error: string;
    success: string;
    dialogRef;
    formSending = false;

    constructor(
        private userProfileService: UserProfileService,
        private router: Router,
        private authService: AuthenticationService,
        private resetPasswordService: ResetPasswordService,
    ) {
    }

    /* goToRegister(): void {
        this.dialog.closeAll();
        this.router.navigate(['/register']);
    }*/

    login(): void {

        const email = (<HTMLInputElement>document.getElementById('email')).value.trim();
        const passwordInput = (<HTMLInputElement>document.getElementById('passwordInput')).value.trim();

        this.enrollmentIdError = false;
        this.passwordError = false;

        const _email = <HTMLInputElement>document.getElementById('emailError');
        const _password = <HTMLInputElement>document.getElementById('errorPassword');
        if (email === "") {
            this.enrollmentIdError = true;
            _email.classList.add('visible');
            _email.classList.remove('hidden');

        } else {
            _email.classList.remove('visible');
            _email.classList.add('hidden');
        }

        if (passwordInput === "") {
                this.passwordError = true;
                _password.classList.add('visible');
                _password.classList.remove('hidden');
            } else {
                _password.classList.remove('visible');
                _password.classList.add('hidden');

            }

        if (email && passwordInput) {
            this.userCredentials.enrollment_id = email;
            this.userCredentials.password = passwordInput;
            this.authenticateUser();
        }
    }

    authenticateUser(): void {

        const _login = <HTMLInputElement>document.getElementById('loginError');
        this.authService.authenticateUser(this.userCredentials).then(
            (user) => {
                this.userProfileService.setUserType(user.user_type);
                this.userProfileService.setUserRole(user.role);
                this.userProfileService.setUserId(user.id);
                this.userProfileService.setAuthToken(user.auth_token);
                this.userProfileService.setAccountType(user.account_type);
                this.userProfileService.removeParentToken();
                this.userProfileService.setLang(user.lang);
                console.log(user.lang);
                if (user.user_type === 'Parent') {
                    this.userProfileService.setParentToken(user.auth_token);
                    this.router.navigate(['/users']);
                } else if (user.user_type === 'Teacher') {
                    this.router.navigate(['/school']);
                } else {
                    this.router.navigate(['/dashboard']);
                }
            },
            (error) => {
                if (error.status === 400) {
                    _login.classList.add('visible');
                    _login.classList.remove('hidden');
                    this.error = error.error;
                }
            }
        );
    }

    submitReset(): void {
        this.formSending = true;

        this.enrollmentIdError = false;

        const email = (<HTMLInputElement>document.getElementById('email')).value.trim();
        const _email = <HTMLInputElement>document.getElementById('emailError');
        if (email === "") {
            this.enrollmentIdError = true;
            _email.classList.add('visible');
            _email.classList.remove('hidden');

        } else {
            _email.classList.remove('visible');
            _email.classList.add('hidden');
        }

        if (email !== "") {
            this.resetPasswordService.resetPasswordMail(email).then(
                (data) => {
                    this.success = 'Por favor revisa tu correo, hemos mandado las instrucciones para cambiar tu contraseña.';
                    this.error = undefined;
                },
                (error) => {
                    this.error = 'Algo salió mal, por favor contacta a soporte';
                    this.success = undefined;
                }
            );
        } else {
            this.error = 'El usuario es obligatorio';
        }
    }
}
