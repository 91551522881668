import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funnels',
  templateUrl: './funnels.component.html',
  styleUrls: ['./funnels.component.scss']
})
export class FunnelsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
