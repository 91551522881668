import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserProfileService } from '../../shared/services/userProfile.service';

import { PinLoginService } from './pin-login.service';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import * as pinDialogs from './pin-login.dialogs';
import { UserCredentials } from '../../shared/models/user.model';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
    selector: 'app-pin-login',
    templateUrl: './pin-login.component.html',
    styleUrls: ['./pin-login.component.scss'],
})
export class PinLoginComponent {
    pin = '';
    error;
    open_login = false;
    teacher_enrollment_id;
    group_name;
    level;
    users = [
        {avatar: 'morado', name: '...', enrollment_id: null}
    ]
    enrollmentId: string;
    password: string;
    enrollmentIdError = false;
    passwordError = false;
    userCredentials = new UserCredentials();
    @ViewChild('pinInput', { static: false }) pinInput;

    constructor(private pinService: PinLoginService, private router: Router, private dialog: MatDialog,
        private userProfileService: UserProfileService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthenticationService,
        ) {

            this.teacher_enrollment_id = this.activatedRoute.snapshot.paramMap.get("teacher_enrollment_id");
            this.group_name = this.activatedRoute.snapshot.paramMap.get("group_name");
            this.level = this.activatedRoute.snapshot.queryParams["level"];

            this.pinService.getStudents(this.teacher_enrollment_id, this.group_name, this.level).then((users) => {
                this.users = users;
                console.log("pin-users: ", this.users);
            });
        }

    
    
    closeLogin() {
        this.open_login = false;
    }

    openLogin() {
        this.open_login = true;
    }

    setUser(user) {
        this.enrollmentId = user.enrollment_id
        this.open_login = true
    }

    addToPin(digit) {
        if ( this.pin.length < 4) {
            this.pin = this.pin + digit
        }
    }

    removeFromPin(digit) {
        if ( this.pin.length > 0) {
           this.pin = this.pin.slice(0, -1);
           console.log("remove from pin", this.pin)
        }
    }

    login(): void {
        this.enrollmentIdError = false;
        this.passwordError = false;

        if (this.enrollmentId === "") {
            this.enrollmentIdError = true;
        }

        if (this.pin === "") {
            this.passwordError = true;
        }
        if (this.pin && this.enrollmentId) {
            this.userCredentials.enrollment_id = this.enrollmentId.trim();
            this.userCredentials.password = this.pin.trim();
            this.authenticateUser();
        }
    }

    authenticateUser(): void {
        this.authService.authenticateUser(this.userCredentials).then(
            async (user) => {
                this.userProfileService.setUserType(user.user_type);
                this.userProfileService.setUserRole(user.role);
                this.userProfileService.setUserId(user.id);
                this.userProfileService.setAuthToken(user.auth_token);
                this.userProfileService.setAccountType(user.account_type);
                this.userProfileService.removeParentToken();
                await this.userProfileService.getUserProfile();
                console.log(this.userProfileService.getLang());
                this.closeLogin();
                if (user.user_type === 'Parent') {
                    this.userProfileService.setParentToken(user.auth_token);
                    this.router.navigate(['/users']);
                } else if (user.user_type === 'Teacher') {
                    this.router.navigate(['/school']);
                } else {
                    this.router.navigate(['/dashboard']);
                }
            },
            (error) => {
                if (error.status === 400) {
                    this.error = error.error;
                    this.dialog.open(SharedPopupComponent, pinDialogs.WRONG_PIN(this));
                }
            }
        );
    }
}
